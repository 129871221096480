import { config } from '@core/app/config';
import { RootState } from '@components/redux/store';
import { getColor } from '@helpers/theme';
import { buildUrl } from '@8ms/helpers/dist/url';
import { IconButton, useClipboard, useColorMode } from "@chakra-ui/react"
import { Tooltip as ChakraTooltip } from '@chakra-ui/tooltip';
import { ShareAlternative } from '@styled-icons/entypo';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components'

const LinkThemed = styled(ShareAlternative)`
	color: ${props => props.getColor(props.colorMode, 'white', ['background', 'base'])};
	width: 20px;
`;

const Share = () =>
{
	const {colorMode}         = useColorMode();
	const router              = useRouter();
	const dashboard           = useSelector((state: RootState) => state.dashboard);
	const [value, setValue]   = useState('');
	const {hasCopied, onCopy} = useClipboard(value);
	
	useEffect(() =>
	{
		if (router.isReady)
		{
			const updated = {
				comparison:      dashboard.comparison,
				current:         dashboard.current,
				languageService: dashboard.languageService,
				platform:        dashboard.platform,
				xtor:            dashboard.xtor,
			};
			
			const encoded = JSON.stringify(updated);
			
			setValue(buildUrl({path: +router.pathname + '?pref=' + encoded, url: config.url}));
		}
	}, [router.isReady, router.pathname, dashboard]);
	
	return (
		<ChakraTooltip
			arrowSize={10}
			hasArrow
			label="Copy this page's URL"
			placement='bottom-end'
			textStyle='caption'
		>
			<IconButton
				aria-label="Copy this page's URL"
				alignSelf='right'
				onClick={onCopy}
				icon={<LinkThemed
					colorMode={colorMode}
					getColor={getColor}
				/>}
			/>
		</ChakraTooltip>
	);
}

export default Share;
