import { Button as ChakraButton, Text, useColorMode } from '@chakra-ui/react';
import { ReactNode } from 'react';

export type ButtonType = {
	children?: ReactNode,
	onClick?: Function,
	variant?: string,
	[chakraOptions: string]: any,
}

const Button = (props: ButtonType) =>
{
	const {colorMode}                                      = useColorMode();
	const {children, onClick, overrides, variant, ...rest} = props;
	
	let buttonVariant, classVariant, spanVariant, hoverColor, chakraButtonStyling;
	
	switch (variant)
	{
		case 'secondary':
			buttonVariant       = 'secondary';
			chakraButtonStyling = {};
			classVariant        = 'secondary';
			hoverColor          = {color: 'white'};
			spanVariant         = 'secondaryCta';
			break;
		
		case 'dateRangeOpen':
			buttonVariant       = 'primary';
			chakraButtonStyling = {
				fontWeight: 500,
			};
			classVariant        = 'primary';
			hoverColor          = {};
			spanVariant         = 'primaryCta';
			break;
		
		case 'datatableExpand':
			buttonVariant       = 'datatableExpand';
			chakraButtonStyling = {
				fontWeight: 500,
			};
			classVariant        = 'primary';
			hoverColor          = {};
			spanVariant         = 'primaryCta';
			break;
		
		default:
			buttonVariant       = 'primary';
			chakraButtonStyling = {};
			classVariant        = 'primary';
			hoverColor          = {};
			spanVariant         = 'primaryCta';
	}
	
	return (
		<ChakraButton
			className={`btn-${classVariant}-${colorMode}`}
			onClick={event => onClick(event)}
			variant={buttonVariant}
			{...rest}
		>
			<Text
				as='span'
				padding='0 0.5rem'
				position='relative'
				sx={{
					[`.btn-${classVariant}-dark:hover &`]:  {
						color: 'background.dark.base',
					},
					[`.btn-${classVariant}-light:hover &`]: hoverColor,
				}}
				variant={spanVariant}
				textStyle={spanVariant}
				zIndex='2'
				{...chakraButtonStyling}
			>
				{children}
			</Text>
		</ChakraButton>
	);
};

Button.defaultProps = {
	children: undefined,
	onClick:  () => {},
	variant:  'primary',
}

export default Button;
