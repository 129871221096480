import { DownloadIcon } from '@chakra-ui/icons';
import { Tooltip as ChakraTooltip } from "@chakra-ui/react"
import { CSVLink } from "react-csv";

type DownloadType = {
	data?: object[],
	getData?: Function,
}

/**
 * Todo: Change the data to be ready on-click to prevent caching issue.
 */
const Download = ({data, getData}: DownloadType) =>
{
	return (
		<div className='inline mr-2'>
			<ChakraTooltip
				arrowSize={10}
				hasArrow
				label='Download the data as a CSV'
				placement='bottom-end'
				textStyle='caption'
			>
				<span>
					<CSVLink
						data={undefined === getData ? data : getData()}
					>
						<DownloadIcon/>
					</CSVLink>
				</span>
			</ChakraTooltip>
		</div>
	);
}

Download.defaultProps = {
	data:    [],
	getData: undefined,
}

export default Download;
