const Maintenance = () =>
{
	return (
		<div className='container'>
			<div className='row'>
				<div className='column py-12 text-center'>
					<h1 className='h3'>Site is currently under maintenance, please check back later.</h1>
				</div>
			</div>
		</div>
	);
}

export default Maintenance;
