import { config } from '@core/app/config';
import { checkSession } from '@components/redux/slices/auth';
import store, { RootState } from '@components/redux/store';
import { Maintenance } from '@components/Maintenance';
import { ACTIVE, INACTIVE } from '@core/helpers/enum';
import { setCookie } from '@helpers/nookies';
import { getLayout as getLayoutApp } from '@layouts/_App';
import { states } from '@8ms/helpers/dist/api';
import { useRouter } from 'next/router';
import { parseCookies } from 'nookies';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// On a logged-in required page, we first check the session
store.dispatch(checkSession());

const LayerLoggedIn = ({children}) =>
{
	const router                                = useRouter();
	const auth                                  = useSelector((state: RootState) => state.auth);
	const [showMaintenance, setShowMaintenance] = useState(states.IDLE);
	
	useEffect(() =>
	{
		// Once the router is ready
		if (router.isReady)
		{
			// Maintenance hasn't been processed yet
			if (states.IDLE === showMaintenance)
			{
				// Maintenance mode
				if ('true' === process.env.NEXT_PUBLIC_MAINTENANCE)
				{
					// Bypass maintenance - set the cookie
					if (undefined !== router.query['maintenance'])
					{
						// Set the cookie (expires in 2 hours)
						setCookie({
							maxAge: 1 * 60 * 60,
							name:   config.cookies.maintenance,
							value:  'true',
						});
					}
					
					// Check if this user should see maintenance mode
					const cookies         = parseCookies();
					const userMaintenance = undefined === cookies[config.cookies.maintenance] ? INACTIVE : ACTIVE;
					
					// Set to show the cookie
					setShowMaintenance(userMaintenance);
				}
				
				// Not maintenance mode
				else
				{
					setShowMaintenance(INACTIVE);
				}
			}
			
			// Not in maintenance mode - Auth has passed
			if ((states.IDLE === showMaintenance || INACTIVE === showMaintenance) && states.SUCCESS === auth.ajaxStatus)
			{
				// User needs to login
				if (states.ERROR === auth.status && '/' !== router.pathname)
				{
					router.push({pathname: '/', query: router.query});
				}
				
				// User already logged in but on log in page
				else if (states.SUCCESS === auth.status && '/' === router.pathname)
				{
					router.push({pathname: config.dashboard.home, query: router.query});
				}
			}
		}
	}, [router.isReady, auth.ajaxStatus, auth.status]);
	
	return (
		<>
			{states.IDLE === showMaintenance && <span>Please wait...</span>}
			{ACTIVE === showMaintenance && <Maintenance/>}
			{INACTIVE === showMaintenance && states.SUCCESS !== auth.ajaxStatus && <span>Please wait...</span>}
			{INACTIVE === showMaintenance && states.SUCCESS === auth.ajaxStatus && children}
		</>
	);
}

export const getLayout = page => getLayoutApp(<LayerLoggedIn>{page}</LayerLoggedIn>);

export default LayerLoggedIn;
