import { post } from '@8ms/helpers/dist/axios';
import { IconButton, Tooltip as ChakraTooltip, useColorMode } from "@chakra-ui/react"
import { SwrsType } from '@helpers/swr';
import { getColor } from '@helpers/theme';
import { Refresh } from '@styled-icons/boxicons-regular/Refresh';
import styled from 'styled-components'

const RefreshThemed = styled(Refresh)`
	color: ${props => props.getColor(props.colorMode, 'white', ['background', 'base'])};
	width: 20px;
`;

type MutateType = {
	swrs: SwrsType,
}

const Mutate = ({swrs}: MutateType) =>
{
	// const [state, setState] = useState(PENDING);
	const {colorMode} = useColorMode();
	
	/**
	useEffect(() => {
		if(isAllData(swrs))
		{
			setState(IDLE);
		}
	}, [swrs]);
	 */
	
	const handleClick = async () =>
	{
		// Only run whilst idle
		//	if (IDLE === state)
		//	{
		// Mutate is running
		//	setState(PENDING);
		
		/**
			let requests = [];
			
			// Convert the object into an array of requests
			for (const swr in swrs)
			{
				// Update the data
				requests.push({
					url:  swrs[swr].mutate.url,
					data: {
						accounts:  swrs[swr].mutate.accounts,
						campaigns: swrs[swr].mutate.campaigns,
						cache:     false,
						input:     swrs[swr].mutate.input,
						preload:   false,
					},
					swr:  swr,
				});
			}
			
			// Execute all refreshes at the same time
			await Promise.all(requests.map(async (request) =>
			{
				await AxiosLib.post(request.url, request.data)
					.then(response =>
					{
						// Update the fetcher but don't revalidate as will fire another request with cache options
						swrs[request.swr].fetcher.swrMutate(response.data, false);
					});
			}));
		 */
		// Clear all data in the swr to trigger the loading state
		// Use our custom state of "undefined" as actual undefined is not acceptable
		for (const swr in swrs)
		{
			swrs[swr].fetcher.swrMutate('undefined', false);
		}
		
		// Serial SWR to prevent issues
		for (const swr in swrs)
		{
			let updated = 'undefined';
			
			// Update the data
			await post({
				data:      {
					accounts:  swrs[swr].mutate.accounts,
					campaigns: swrs[swr].mutate.campaigns,
					cache:     false,
					input:     swrs[swr].mutate.input,
					preload:   false,
				},
				onSuccess: response =>
				           {
					           updated = response;
				           },
				url:       swrs[swr].mutate.url,
			});
			
			// Update the fetcher but don't revalidate as will fire another request with cache options
			swrs[swr].fetcher.swrMutate(updated, false);
			
			/**
				// Update state
				swrs[swr].fetcher.isData    = true;
				swrs[swr].fetcher.isError   = false;
				swrs[swr].fetcher.isLoading = false;
			 */
		}
		
		// Ready to run again
		//	setState(IDLE);
		//}
	}
	
	return (
		<ChakraTooltip
			arrowSize={10}
			hasArrow
			label='Refresh the data'
			placement='bottom-end'
			textStyle='caption'
		>
			<IconButton
				aria-label='Refresh the data'
				icon={<RefreshThemed
					colorMode={colorMode}
					getColor={getColor}
				/>}
				onClick={handleClick}
			/>
		</ChakraTooltip>
	);
}

Mutate.defaultProps = {
	swrs: [],
}

export default Mutate;

