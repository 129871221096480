import { Download, Mutate } from '@components/Button';
import { SwrsType } from '@helpers/swr';
import { states } from '@8ms/helpers/dist/api';
import { isAllData } from '@8ms/helpers/dist/swr';
import { Box } from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';

type ChartActionsType = {
	children?: ReactNode,
	data?: object[],
	getData?: Function,
	swrs: SwrsType,
}

const ChartActions = ({children, data, getData, swrs}: ChartActionsType) =>
{
	const [state, setState] = useState(states.PENDING);
	
	useEffect(() =>
	{
		if (isAllData({swrs}))
		{
			setState(states.IDLE);
		}
		else
		{
			setState(states.PENDING);
		}
	}, [swrs]);
	
	if (states.PENDING === state)
	{
		return <></>
	}
	
	return (
		<Box
			alignItems='flex-end'
			display='flex'
			position='absolute'
			right='1rem'
			top='8px'
		>
			{children}
			<Download
				data={data}
				getData={getData}
			/>
			<Mutate
				swrs={swrs}
			/>
		</Box>
	);
}

ChartActions.defaultProperties = {
	children: undefined,
	data:     undefined,
	getData:  undefined,
	swrs:     [],
}

export default ChartActions;
